.ijf-form {
    font-size: 14px;

    .line {
        display: flex;
        margin-bottom: 15px;
        @media (max-width: 800px) {
            flex-direction: column;
        }
        &--center {
            justify-content: center;
        }

        .input_table{
            width: 100%;
            margin-right:15px;
        }

        .line-element {
            flex: 1;
            margin-left: 15px;
            @media (max-width: 800px) {
                margin-left: 0;
                margin-top: 15px;
            }
            &:first-child {
                margin-left: 0;
                @media (max-width: 800px) {
                    margin-top: 0;
                }
            }

            &--button{
                display:inline-flex;
                width: 100%;

                .row-inputs {
                    display: inline-flex;
                    flex: 1;

                    .input_box{
                        flex: 1;
                        margin-right: 10px;
                    }
                }

                input {
                    flex: 1;
                    margin-right: 10px;
                }

                button {
                    width: 44px;
                    margin-top: 27px;
                }
            }
        }

        &--button {
            display:inline-flex;
            width: 100%;

            input {
                flex: 10;
                margin-right: 10px;
            }

            button {
                flex: 1;
            }
        }

        label {
            display:block;
            font-weight: 700;
            margin-bottom: 0.5em;
        }

        input, textarea, select {
            border: 1px solid #d9d9d9;
            padding: 8px;
            width: 100%;
            font-size: 14px;
            box-sizing: border-box;
            height: 38px;
        }

        textarea {
            width: 100%;
            height: 5em;
        }

        button {
            width: 100%;
        }

        .multiple-options {
            display: inline-block;
        }

        .label-checkbox {
            cursor: pointer;
            display: flex;
            align-items: center;
            font-weight: 400;
            height: 24px;

            .input_field{
                margin-right: 5px;
                width: 20px;
            }
        }
        &--agree{
            input[type="checkbox"]{
                height: 15px;
                width: 15px;
                display: inline-block;
            }
            input[type="text"]{
                display: inline-block;
                width: 150px;
            }
            a{
                text-decoration: underline;
            }
        }

        .align-right {
            margin-left: auto;
            margin-right: 0px;

            @media screen and (max-width: 800px){
                margin-left: 0px;
                margin-right: 20px;
            }
        }
    }
}