.video-grid {
    display: grid;
    // grid-template-columns: repeat(auto-fill,minmax(260px,1fr));
    grid-template-columns: repeat(auto-fill,minmax(220px,1fr));
    grid-gap: 30px 20px;
    margin: 25px 0 10px;
    .video-component {
        cursor: pointer;
        &:hover {
            .video-component__thumb  {
                filter: brightness(125%);
            }
            .video-component__title {
                color: $colorMain;
            }
        }
        &__thumb {
            padding-bottom: 56%;
            position: relative;
            background-size: 100%;
            background-position: 50%;
            transition: filter .2s ease;
            filter: brightness(100%);
        }
        &__category {
            color: #555;
            margin: 10px 0 5px;
        }
        &__title {
            font-size: 18px;
            font-weight: 600;
            line-height: 1.2em;
            // margin: 10px 0;
            margin-bottom: 7px;
            transition: color .2s ease;
            overflow: hidden;
            margin-top: 7px;
        }
        &__author {
            color: #555;
            margin-bottom: 10px;
        }
        &__views, &__votes, &__rank {
            display: inline-block;
            color: #555;
        }
        &__votes {
            font-weight: 600;
            margin: 0 5px;
            padding: 0 5px;
            border-left: 1px solid $greyLineColor;
            border-right: 1px solid $greyLineColor;
        }
        &--voted {
            .video-component__thumb {
                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    border-width: 5px;
                    border-style: solid;
                    border-image: $goldBorderGradient;
                    border-image-slice: 1;
                }
                &::after {
                    content:'Voted';
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    font-size: 16px;
                    font-weight: 700;
                    color: #333;
                    background: $goldColor;
                    padding: 7px 8px 5px;
                }
            }
        }
    }
}