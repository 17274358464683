$blueColor: #36335d;
$greyLineColor: #c9c9c9;
$goldColor: #f9ce89;
$goldColor2: #d59f46;
$red2Color: $goldColor;
$goldGradient: linear-gradient(-80deg, #a37a35,#dbb372,#a37a35);
$goldGradient2: linear-gradient(-80deg,#a37a35,#dbb372);
$goldLineColor: #f9ce89;
$goldBorderGradient: linear-gradient(-80deg, #d59f46, #ffd085, #d59f46);
$violet: #36335d;
$linkOnWhite: lighten($violet, 20);

//PASTEL COLORS
// $gradientHeader: linear-gradient(#cd7772, #f49f76);
// $gradientHeaderReverse: linear-gradient(#f49f76, #cd7772);
// $gradientPageHeader: linear-gradient(#f3be7c, #38aaa2);
// $gradientPageHeaderReverse: linear-gradient(#38aaa2, #f3be7c);
// $colorMainMenu: #ffc82f;
// $colorMain: #4ebfb7;
// $colorMainDarker: #43a8a1;
// $color2: #ffdf84;

//SATURATED COLORS
// $gradientHeader: linear-gradient(#e07872, #f09163);
// $gradientHeader: linear-gradient(#d3554d, #f29163);
// $gradientHeaderReverse: linear-gradient(#f29163, #d3554d);
// $gradientPageHeader: linear-gradient(#ffac62, #09b1a5);
// $gradientPageHeaderReverse: linear-gradient(#09b1a5, #ffac62);
// $colorMainMenu: #ffdf84;
// $colorMain: #32c8be;
// $colorMainDarker: #28b4aa;
// $color2: #ffdf84;

//BLUE
// $gradientHeader: linear-gradient(#e07872, #f09163);
// $gradientHeader: linear-gradient(#d3554d, #f29163);
// $gradientHeaderReverse: linear-gradient(#f29163, #d3554d);
// $gradientPageHeader: linear-gradient(#ffac62, #0079c1);
// $gradientPageHeaderReverse: linear-gradient(#0079c1, #ffac62);
// $colorMainMenu: #ffdf84;
// $colorMain: #129edd;
// $colorMainDarker: #137bbe;
// $color2: #ffdf84;

//BLUE / MORE RED
$gradientHeader: linear-gradient(#c02b2b, #f45858);
$gradientHeaderReverse: linear-gradient(#f45858, #c02b2b);
$gradientPageHeader: linear-gradient(#ff8662, #0079c1);
$gradientPageHeaderReverse: linear-gradient(#0079c1, #ff8662);
$gradientBlue: linear-gradient(#1390e3, #0f619f);
$colorMainMenu: #ffdf84;
$colorMain: #129edd;
$colorMainDarker: #137bbe;
$color2: #ffdf84;




$maxWidth: 1000px;
$headerHeight: 94px;
$headerHeightMobile: 50px;


main {
    
    .main-content {
        max-width: $maxWidth;
        margin: 0 auto 40px;
        padding-top: 20px;
        @media (max-width: $maxWidth) {
            padding-top: 0;
            margin-bottom: 20px;
        }
        &--with-page-header {
            padding-top: 0px;
        }
        &--no-bottom-margin {
            margin-bottom: 0px;
        }
        .content-box{
            background: #fff;
            flex: 1;
            box-shadow: 0 5px 7px rgba(0, 0, 0, .25);
            padding: 20px;
            margin-bottom: 20px;
            @media all and (max-width: 1024px) {
                box-shadow: none;
                padding: 20px 15px;
                margin-bottom: 0;
                &:not(:first-child) {
                    border-top: 1px solid $greyLineColor;
                }
            }
            .content-box__title {
                display: flex;
                padding-bottom: 20px;
                & h1, h2{
                    font-size: 24px;
                    font-weight: 700;
                    margin: auto 20px auto 0;
                    padding: 0;
                    flex: 1;
                }
                h3 {
                    margin: auto 20px auto 0;
                    padding: 0;
                }
                .btn {
                    height: 0%;
                    align-self: center;
                    @media screen and (max-width: 640px) {
                        align-self: flex-start;
                        margin-top: 10px;
                    }
                }
                @media screen and (max-width: 640px) {
                    flex-direction: column;
                }
            }
        }
    }
}

.no-flicker{
    -webkit-backface-visibility: hidden;
    -webkit-transform: scale(1);
}

// HB - headerbody
.template-HB {
    flex: 1;
    &--scrollable {
        overflow-y: scroll;
    }
    &--with-header-offset{
        margin-top: 94px;
        @media (max-width: 1024px) {
            margin-top: 49px;
        }
    }
    &__header {
        position: fixed;
        z-index: 900;
        width: 100%;
        // height: 111px;
        height: $headerHeight;
        color: #fff;
        background: $gradientHeader;
        transition: background-image ease .3s, border-bottom ease .3s;
        border-bottom: 1px solid hsla(0, 0%, 100%, .3);
        &--animable{
            // background-image: linear-gradient(to bottom, rgba($blueColor, .3), rgba($blueColor, .1));
            background: none;
            &::before{
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                opacity: .6;
                background: $gradientHeader;
                transition: opacity .3s ease;
            }
            border-bottom: 1px solid rgba(255, 200, 174, 0.6);
        }
        &--with_bg{
            &::before{
                opacity: 1;
            }
            border-bottom: 1px solid rgba(255, 200, 174, 1);
        }
        // border-bottom: 1px solid hsla(0, 0%, 100%, .3);
        @media (max-width: 1024px) {
            height: $headerHeightMobile;
        }
        .ijf-logo{
            display: flex;
            align-items: flex-end;
            padding-bottom: 15px;
            margin-left: 10px;
            img{
                width: 57px;
                height: 57px;
            }
            @media all and (max-width: 1024px) {
                padding-bottom: 5px;
                img{
                    width: 35px;
                    height: 35px;
                }
            }
        }
    }
    &__headerlimit {
        max-width: 1000px;
        margin: 0 auto;
        height: 100%;
    }
    &__headeritems {
        position: relative;
        display: flex;
        height: 100%;
        @media all and (max-width: 1024px) {
            justify-content: center;
        }
    }
    &__body {
        display: flex;
        justify-content: center;
        padding: 20px 0;
        @media all and (max-width: 1000px) {
            padding: 0;
        }
    }
    &__contentbox {
        background: #fff;
        flex: 1;
        box-shadow: 0 5px 7px rgba(0, 0, 0, .25);
        max-width: 1000px;
        padding: 20px;
        @media all and (max-width: 1024px) {
            box-shadow: none;
            padding: 20px 15px;
        }
    }
}

.required-asterisk{
    color: red;
}

.hidden{
    display: none !important;
}

.image-instead-of-video{
    width: 100%;
    height: auto;
}

.btn {
    cursor: pointer;
    border: 0;
    color: #fff;
    background-color: $colorMain;
    display: inline-block;
    vertical-align: middle;
    font-weight: 600;
    padding: 10px 15px;
    overflow: hidden;
    position: relative;
    border: 1px solid transparent;
    text-align: center;
    font-size: 14px;
    font-family: inherit;
    line-height: inherit;
    transition: all .2s ease;
    filter: brightness(100%);
    @media all and (max-width: 1024px) {
        padding: 8px 10px;
    }
    &::after {
        content: "";
        position: absolute;
        transition: all .3s ease;
        width: 0;
        height: 500%;
        background: hsla(0, 0%, 100%, .15);
        z-index: 1;
        right: 0;
        top: 0;
        margin: -5px 0 0 -5px;
        transform-origin: 0 0;
        transform: rotate(-20deg);
    }
    &:hover {
        filter: brightness(105%);
        &::after {
            width: 40%;
        }
    }
    &--medium {
        font-size: 16px;
        padding: 15px 20px;
    }
    &--big {
        font-size: 20px;
        padding: 20px 30px;
    }
    &--green {
        background-color: #03b500;
    }
    &--grey {
        color: #333;
        background-color: #e5e5e5;
        border: 1px solid #b9b9b9;
    }
    &--white {
        color: #333;
        background-color: #f9f9f9;
        border: 1px solid #b9b9b9;
    }
    &--nowrap {
        white-space: nowrap;
    }
    &--icon {
        cursor: pointer;
        border: 0;
    }
    &--fb_blue {
        background: #4267b2;
    }
    &--with-icon {
        display: inline-flex;
        align-items: center;
        svg {
            width: 16px;
            height: 16px;
            margin-right: 5px;
        }
        &-right {
            svg {
                margin-right: 0;
                margin-left: 5px;
            }
        }
    }
    &--with-icon-right {
        display: flex;
        align-items: center;
        svg {
            width: 16px;
            height: 16px;
            margin-left: 5px;
        }
    }
    &:disabled {
        cursor: default;
        background-color: #ccc;
        filter: none;
        &::after {
            display: none;
        }
    }
    &--ippon-black {
        position: relative;
        background: url('btn_ippon_black_bg.png');
        background-size: contain;
        border-radius: 5px;
        border: 1px solid #58585a;
        height: 60px;
        width: 220px;
        &::before {
            content:'';
            position: absolute;
            background-image: url('btn_ippon_black_txt.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            top: 12%;
            bottom: 12%;
            left: 12%;
            right: 12%;
        }
    }
    &--ippon-white {
        position: relative;
        background: url('btn_ippon_white_bg.png');
        background-size: contain;
        border-radius: 5px;
        border: 1px solid #a7a9ac;
        height: 60px;
        width: 220px;
        &::before {
            content:'';
            position: absolute;
            background-image: url('btn_ippon_white_txt.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            top: 12%;
            bottom: 12%;
            left: 12%;
            right: 12%;
        }
        &:hover{
            background: url('btn_ippon_black_bg.png');
            border: 1px solid #58585a;

        }
    }
}

select {
    cursor: pointer;
    height: 36px;
    padding: 0 10px;
    border: 1px solid #b9b9b9;
    font-weight: 600;
    font-family: inherit;
    text-overflow: ellipsis;
    @media all and (max-width: 1024px) {
        height: 32px;
        padding: 0 5px;
    }
}

input {
    height: 36px;
    padding: 0 10px;
    border: 1px solid #b9b9b9;
    font-family: inherit;
    @media all and (max-width: 1024px) {
        height: 32px;
        padding: 0 5px;
    }
}

svg {
    height: 100%;
    width: 100%;
}

a:not(.btn) {
    &:hover {
        color: $colorMainDarker;
    }
}

a {
    font-size: 14px;
    text-decoration: none;
    color: inherit;
    transition: color 0.1s linear;
}

.menulogo {
    align-self: center;
    margin-right: 20px;
    @media all and (max-width: 1024px) {
        margin-right: 0;
        margin-left: 0;
    }
    img {
        height: 62px;
        @media all and (max-width: 1024px) {
            height: 40px;
        }
    }
}

.menuitems {
    display: flex;
    width: 100%;
    align-items: flex-end;
    @media all and (max-width: 1024px) {
        display: none;
    }
    &__item {
        .btn {
            margin-bottom: 12px;
        }
    }
    &__item:not(div) {
        // &:last-child {
        //     padding-right: 0 !important;
        // }
        position: relative;
        padding: 20px 18px 22px;
        color: inherit;
        text-decoration: none;
        &::after {
            content: "";
            display: inline-block;
            position: absolute;
            bottom: 0;
            left: 50%;
            right: 50%;
            height: 5px;
            background: rgba(255, 21, 83, 0);
            transition: background-color .4s, left .2s, right .2s;
        }
        &:hover {
            color: inherit;
            &::after {
                left: 0;
                right: 0;
                background: hsla(0, 0%, 100%, .4);
            }
        }
    }
    a.menuitems__item {
        &--active {
            &::after {
                left: 0;
                right: 0;
                // background: hsla(0, 0%, 100%, .4);
                background: $color2;
            }
        }
    }
    div.menuitems__item {
        &--filler {
            flex-grow: 1;
        }
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.messages {
    .title {
        font-weight: 700;
        font-size: 16px;
        padding: 20px 0 20px;
        margin-top: 40px;
        border-top: 1px solid $greyLineColor;
    }
    .comment {
        margin-bottom: 10px;
        @media (max-width: 800px) {
            margin-bottom: 20px;
        }
        &__title_and_time {
            margin-bottom: 5px;
        }
        &__title, &__time, &__separator {
            display: inline-block;
            color: #555;
        }
        &__separator {
            padding: 0 5px;
        }
        &__message {
            display: block;
            margin-bottom: 2px;
            .text {
                display: inline-block;
                padding: 15px;
                border-radius: 10px;
            }
        }
        &--mine {
            text-align: right;
            .text {
                background: #d3d1eb;
            }
        }
        &--notmine {
            .text {
                background: #e4e4e4;
            }
        }        
    }
    .add_comment {
        display: flex;
        margin-top: 20px;
        @media (max-width: 500px){
            flex-direction: column;
        }
        &__comment{
            flex: 1;
            display: flex;
        }
        &__btns{
            display: flex;
            .btn {
                &:not(:first-child) {
                    margin-left: 5px;
                }
            }
        }
        textarea {
            flex: 1;
            margin-right: 10px;
            @media (max-width: 500px){
                margin-right: 0px;
                margin-bottom: 5px;
            }
        }
        .btn {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 100px;
            @media (max-width: 500px){
                flex: 1;
            }
        }
    }
}

@mixin bottom-line-decoration($color1, $height: 3px, $color2: $color1) {
    position: relative;

    &::after {
        content: '';
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 50%;
        right: 50%;
        height: $height;
        background: transparentize($color1, 1.0);
        transition: background-color 0.4s, left 0.2s, right 0.2s;
    }

    &.hover::after,
    &:hover::after {
        left: 0;
        right: 0;
        background: transparentize($color2, 0.6);
    }

    &.active::after, &.selected::after {
        left: 0;
        right: 0;
        background: $color1;
    }
}