.article-share {
    display: flex;
    margin-top: 15px;
    &__title {
        align-self: center;
        font-size: 16px;
        @media (max-width: 640px) {
            font-size: 14px;
        }
    }
    &__icons {
        display: flex;
        justify-content: space-between;
        a {
            display: block;
            flex: 1;
            height: 32px;
            width: 32px;
            margin-left: 15px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 50%;
            cursor: pointer;
            &.facebook--white {
                background-image: url('./social/fb_w.png');
            }
            &.twitter--white {
                background-image: url('./social/twitter_w.png');
            }
            &.email--white {
                background-image: url('./social/email_w.png');
            }
            &.share--white {
                background-image: url('./social/share_w.png');
            }
            
            &.facebook{
                background-image: url('./social/fb.png');
            }
            &.twitter{
                background-image: url('./social/twitter.png');
            }
            &.email{
                background-image: url('./social/email.png');
            }
            &.share{
                background-image: url('./social/share.png');
            }
        }
    }
}