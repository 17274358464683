.component--filters {
	.filters-row {
		.select-rank-by {
			flex: 1;
		}

		.filter-rank-by {
			flex: 2;
		}
	}
}
