/* open-sans-300 - latin-ext_latin */
@font-face {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 300;
		src: url('fonts/open-sans-v15-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
		src: local('Open Sans Light'), local('OpenSans-Light'),
				 url('fonts/open-sans-v15-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
				 url('fonts/open-sans-v15-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
				 url('fonts/open-sans-v15-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
				 url('fonts/open-sans-v15-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
				 url('fonts/open-sans-v15-latin-ext_latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
	}

	/* open-sans-regular - latin-ext_latin */
	@font-face {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 400;
		src: url('fonts/open-sans-v15-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
		src: local('Open Sans Regular'), local('OpenSans-Regular'),
				 url('fonts/open-sans-v15-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
				 url('fonts/open-sans-v15-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
				 url('fonts/open-sans-v15-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
				 url('fonts/open-sans-v15-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
				 url('fonts/open-sans-v15-latin-ext_latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
	}

	/* open-sans-italic - latin-ext_latin */
	@font-face {
		font-family: 'Open Sans';
		font-style: italic;
		font-weight: 400;
		src: url('fonts/open-sans-v15-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
		src: local('Open Sans Italic'), local('OpenSans-Italic'),
				 url('fonts/open-sans-v15-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
				 url('fonts/open-sans-v15-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
				 url('fonts/open-sans-v15-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
				 url('fonts/open-sans-v15-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
				 url('fonts/open-sans-v15-latin-ext_latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
	}

	/* open-sans-600 - latin-ext_latin */
	@font-face {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 600;
		src: url('fonts/open-sans-v15-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
		src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
				 url('fonts/open-sans-v15-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
				 url('fonts/open-sans-v15-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
				 url('fonts/open-sans-v15-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
				 url('fonts/open-sans-v15-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
				 url('fonts/open-sans-v15-latin-ext_latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
	}

	/* open-sans-700 - latin-ext_latin */
	@font-face {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 700;
		src: url('fonts/open-sans-v15-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
		src: local('Open Sans Bold'), local('OpenSans-Bold'),
				 url('fonts/open-sans-v15-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
				 url('fonts/open-sans-v15-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
				 url('fonts/open-sans-v15-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
				 url('fonts/open-sans-v15-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
				 url('fonts/open-sans-v15-latin-ext_latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
	}

	/* open-sans-800 - latin-ext_latin */
	@font-face {
		font-family: 'Open Sans';
		font-style: normal;
		font-weight: 800;
		src: url('fonts/open-sans-v15-latin-ext_latin-800.eot'); /* IE9 Compat Modes */
		src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
				 url('fonts/open-sans-v15-latin-ext_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
				 url('fonts/open-sans-v15-latin-ext_latin-800.woff2') format('woff2'), /* Super Modern Browsers */
				 url('fonts/open-sans-v15-latin-ext_latin-800.woff') format('woff'), /* Modern Browsers */
				 url('fonts/open-sans-v15-latin-ext_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
				 url('fonts/open-sans-v15-latin-ext_latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
	}

*, *:before, *:after {
		box-sizing: inherit;
		//font-weight: normal;
}
@import 'normalize.css';


html {
	width:100%;
	height: 100%;
	// display: flex;
	box-sizing: border-box;
	padding: 0;
	color: #333333;
	background: #e2e2e2;
	font-family: 'Open Sans';
	line-height: 1.2;
	font-size: 14px;
	@media all and (max-width: 1024px){
		height: auto;
		width: 100%;
		background: #fff;
	}
	body {
		padding: 0;
		margin: 0;
		width:100%;
		min-height: 100%;
		display: flex;
		flex-direction: column;
		main {
			flex-grow: 1;
		}
	}
	@import "./adminVideos.scss";
	@import './shared.scss';
	@import './upload.scss';
	@import './table.scss';
	@import './form.scss';
	@import './PageContestBrowserStyle.scss';
	@import './ComponentFilters.scss';
	@import './browse.scss';
	@import './ranking.scss';
	@import './watch.scss';
	@import './ComponentArticleShare.scss';
	@import './ComponentVideoGrid.scss';
	@import './ModalComponent.scss';
	@import "./ComponentMobileMenu.scss";
	@import "./ComponentTimeline.scss";
	@import 'ComponentPageHeaderStyle.scss';
	@import 'ComponentPageHeaderV2.scss';
	@import 'ComponentAccountBar.scss';
	@import 'ComponentDropdown.scss';
}

.text--color{
	color: $colorMainDarker;
}

.hero-throw{
	color: #fff;
	text-align: center;
	//width: 100%;
	//height: 100vh;
	background-image: url('./IJF_marrakech_video_bg.jpg');
	background-size: cover;
	background-position: center;
	display: flex;
	align-items: center;
	position: relative;
	padding-top: calc(#{$headerHeight} + 60px);
	padding-bottom: 60px;
	@media (max-width: 1024px) {
		height: auto;
		padding-top: calc(#{$headerHeightMobile} + 40px);
		padding-bottom: 40px;
	}
	.categories{
		margin-top: 20px;
	}
	&__contest_finished_title{
		font-size: 35px;
		padding: 80px 0;
		font-weight: 300;
		img{
			margin-bottom: 30px;
			width: 100%;
			max-width: 300px;
		}
		@media (max-width: 1024px) {
			font-size: 25px
		}
	}
	&__bg-video {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			overflow: hidden;
			video{
					position: absolute;
					top: 50%;
					left: 50%;
					width: auto;
					height: auto;
					min-width: 100%;
					min-height: 100%;
					transform: translate(-50%, -50%);
			}
	}
	&__content {
		position: relative;
		max-width: 1000px;
		margin: 0 auto;
		z-index: 2;
	}
	&__title {
		text-transform: uppercase;
		font-weight: 700;
		font-size: 36px;
		padding: 0px 20px 20px;
		line-height: 1.2;
		@media (max-width: 1366px) {
			font-size: 30px;
			padding-bottom: 10px;
		}
		@media (max-width: 640px) {
			font-size: 24px;
			padding-bottom: 10px;
		}
		@media (max-height: 630px) and (min-width: 1000px) {
			font-size: 20px;
			padding-bottom: 10px;
		}
		&--color{
			font-size: 50px;
			color: $color2;
			padding-bottom: 0;
			padding-top: 10px;
			@media (max-width: 1366px) {
				font-size: 44px;
			}
			@media (max-width: 640px) {
				font-size: 30px;
			}

			@media (max-height: 630px) and (min-width: 1000px) {
				font-size: 30px;
			}
		}
	}
	&__subtitle {
		font-size: 16px;
		padding: 5px 20px;
		line-height: 1.2;
		&--win-to-worlds {
		}
	}

	&__countdown {
		margin: 40px 0;
		padding: 0 20px;
		@media (max-width: 1366px) {
			margin: 30px 0;
		}
		.container {
			display: flex;
			flex-direction: row;
			justify-content: center;
			margin-bottom: 5px;
			@media (max-width: 1366px) {
				margin-bottom: 0px;
			}
			@media (max-width: 640px) {
				justify-content: space-between;
			}
			.timer {
				@media (max-width: 640px) {
					width: 32%;
				}
				&__title {
					font-size: 16px;
					font-weight: 600;
				}
				&__number {
					padding: 20px 25px;
					background-color: rgba(249,206,137,0.2);
					margin: 10px;
					font-size: 63px;
					font-weight: 700;
					min-width: 130px;
					@media (max-width: 1366px) {
						font-size: 46px;
						padding: 15px 20px;
					}
					@media (max-width: 640px) {
						font-size: 30px;
						min-width: 75px;
						margin: 5px 0;
					}
					@media (max-height: 800px) and (min-width: 1000px) {
						padding: 10px 15px;
						font-size: 25px;
					}
				}
			}
		}
		.title {
			font-weight: 700;
			text-transform: uppercase;
			font-size: 24px;
			@media (max-width: 1366px) {
				font-size: 20px;
			}
		}
	}
}

.winners-list{

	&__sectiontitle{
		font-weight: 300;
		font-size: 26px;
	}
	&__section{
		margin-top: 20px;
		margin-left: auto;
		margin-right: auto;
		background: #fff;
		box-shadow: 0 5px 7px rgba(0, 0, 0, 0.25);
		max-width: 1000px;
		padding: 20px;

		&:first-child{
			margin-top: 0;
		}
		.videos-container{
			$self: &;
			padding: 20px 0;
			&__video{
				margin: 0 auto;
				margin-top: 40px;
				border-top: 1px solid #c3c3c3;
				padding-top: 40px;
				&:first-child{
					margin-top: 20px;
					border-top: none;
					padding-top: 0;
				}

				.btn{
					margin-top: 20px;
				}
			}
			&__title{
				margin-bottom: 20px;
				font-size: 22px;
				font-weight: 300;
			}
			&--hidden{
				.videos-container__video{
					display: none;
				}
			}
		}
	}
}

.section {
	text-align: center;
	font-size: 16px;
	position: relative;
	overflow: hidden;
    &--winners {
		background: #e2e2e2;
		padding: 20px;
    }
	&--first {
		background: white;
	}
	&--win-and-travel {
		background: #49457e;
		// background-image: url('./purple-gradient-bg.jpg');
		background: $gradientPageHeaderReverse;
		background-size: cover;
		background-position: center;
		color: white;

	}
	&__content {
		position: relative;
		max-width: 1000px;
		margin: 0 auto;
		padding: 70px 0 80px;
		line-height: 1.2;
		@media (max-width: 1024px) {
			padding: 30px 20px;
		}
	}
	&__title {
		font-size: 30px;
		font-weight: 700;
		margin-bottom: 20px;
		@media (max-width: 640px) {
			font-size: 24px;
		}
	}
	&__subtitle {
		margin-bottom: 36px;
	}
	&__background-image {
		position: absolute;
		opacity: .25;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center;
	}
	.bullets {
		margin-bottom: 36px;
		@media (max-width: 360px) {
			margin-bottom: 30px;
		}
		.title {
			font-weight: 700;
			margin-bottom: 15px;
		}
		&__columns {
			display: flex;
			flex-direction: row;
			@media (max-width: 640px) {
				flex-direction: column;
			}
		}
		&__column {
			flex: 1;
			text-align: right;
			padding: 0 10px;
			@media (max-width: 640px) {
				text-align: center;
			}
            &:first-child{
                div {
                    padding-right: 20px;
                    position: relative;
                    @media (max-width: 640px) {
                        padding-right: 0;
                    }
                    &:after{
                        content: '';
                        background: $colorMainDarker;
                        border-radius: 50%;
                        width: 8px;
                        height: 8px;
                        position: absolute;
                        top: 5px;
                        right: 4px;
                        @media (max-width: 640px) {
                            display: none;
                        }
                    }
                }
			}
            &:last-child {
				text-align: left;
				@media (max-width: 640px) {
					text-align: center;
				}
                div {
                    padding-left: 20px;
                    position: relative;
                    @media (max-width: 640px) {
                        padding-left: 0;
                    }
                    &:after {
                        content: '';
                        background: $colorMainDarker;
                        border-radius: 50%;
                        width: 8px;
                        height: 8px;
                        position: absolute;
                        top: 5px;
                        left: 4px;
                        @media (max-width: 640px) {
                            display: none;
                        }
                    }
                }
            }
			div {
				padding-bottom: 10px;
			}
			a {
				font-size: 16px;
				font-weight: 600;
			}

		}
	}

	.awards {
		&__list{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			@media (max-width: 850px) {
				flex-direction: column;
			}
			.award {
				display: flex;
				flex-direction: column;
				// width: 32%;
				width: 24%;
				border: 1px solid;
				border-color: $colorMainDarker;
				// border-image: $goldBorderGradient;
				// border-image-slice: 1;
				@media (max-width: 850px) {
					width: 100%;
					margin-bottom: 20px;
				}
				.image {
					width: 100%;
					display: block;
				}
				.texts {
					flex: 1;
					font-size: 20px;
					font-weight: 700;
					background: $colorMainDarker;
					padding: 15px;
					&__place {
						font-size: 16px;
					}
				}
			}
		}
	}
}

.categories {
	text-align: center;
	.title {
		font-weight: 700;
		margin-bottom: 15px;
	}
	&__list {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		@media (max-width: 800px) {
			// flex-direction: column;
			display: block;
		}
		.category {
			position: relative;
			cursor: pointer;
			background-color: #49457e;
			border-width: 1px;
			border-style: solid;
			border-image: $goldBorderGradient;
			border-image-slice: 1;
			width: 19%;
			background-image: url('./purple-gradient-bg.jpg');
			background-size: cover;
			background-position: center;
			padding: 25px 10px;
			filter: brightness(1);
			transition: filter 0.2s ease;
			@media (max-width: 800px) {
				width: 100%;
				margin-bottom: 10px;
				display: flex;
				align-items: center;
				padding: 15px;
				background-size: auto;
			}
			@media (max-width: 320px) {
				padding: 15px 10px;
			}
			&:hover {
				filter: brightness(1.3);
			}
			.icon {
				height: 85px;
				@media (max-width: 1024px) {
					height: 70px;
				}
				@media (max-width: 800px) {
					height: 50px;
				}
			}
			.title {
				color: white;
				font-size: 22px;
				margin-top: 20px;
				margin-bottom: 0;
				@media (max-width: 1024px) {
					font-size: 18px;
				}
				@media (max-width: 800px) {
					flex: 1;
					margin-top: 0px;
					margin-left: 20px;
					font-size: 20px;
					text-align: left;
				}
				@media (max-width: 320px) {
					margin-left: 5px;
				}
			}
			&--active{
				background-image: url('./purple-gradient-bg.jpg');
				filter: brightness(1.3);
				background-position: unset;
				&::before {
                    content: '';
                    position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
                    border-width: 5px;
                    border-style: solid;
                    border-image: $goldBorderGradient;
                    border-image-slice: 1;
                }
				.title {
					color: $goldColor;
				}
			}
			.voted {
				display: flex;
				justify-content: center;
				align-items: center;
				color: $goldColor;
				font-size: 18px;
				font-weight: 700;
				margin-top: 10px;
				svg {
					height: 20px;
					width: auto;
					margin-right: 5px;
				}
				@media (max-width: 800px) {
					margin-top: 0;
					flex-direction: column;
					font-size: 16px;
				}
			}
			&--voted {
				padding-bottom: 15px;
			}
		}
	}
}

.sponsors {
	padding: 40px 0;
    box-sizing: border-box;
    background-color: #fff;
	width: 100%;
	@media (max-width: 1024px) {
		border-top: 1px solid $greyLineColor;
	}
    &__container {
		box-sizing: border-box;
		display: flex;
		max-width: 1280px;
		margin: 0 auto;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		a {
			min-width: 0;
			box-sizing: border-box;
			margin: 20px 30px;
			@media screen and (max-width: 960px) {
				margin: 15px 20px;
			}
			@media screen and (max-width: 480px) {
				margin: 10px 15px;
			}
			img {
				min-width: 0;
				height: auto;
				vertical-align: center;
				max-width: 150px; // 6 in row at 1280px

				@media screen and (max-width: 960px) {
				max-width: 140px; // 5 in row
				}
				@media screen and (max-width: 640px) {
				max-width: 110px; // 4 in row
				}
				@media screen and (max-width: 480px) {
				max-width: 80px;
				}
			}
		}
	}
}
.footer2018 {
	background: #333;
	line-height: 16px;

	.footer2018__holder {
		padding: 20px 0;
		max-width: $maxWidth;
		margin: 0 auto;
		display: flex;
		font-size: 14px;
		color: #aaa;
		@media (max-width: 1024px ){
			padding: 20px;
		}

		a {
		color: #aaa;
		text-decoration: none;
		margin: 0 10px;
			&:hover {
				text-decoration: underline;
			}
		}

		.footer2018__legal {
			flex: 2;
			line-height: 1.5;
		}

		.footer2018__legal-links {
			a:first-child {
				margin-left: 0;
			}
		}

		.footer2018__please-help {
		flex: 1;
		text-align: right;
		align-self: center;
			a {
				font-weight: 700;
				margin: 0;
			}
		}

		@media screen and (max-width: 900px) {
			flex-direction: column;
			text-align: center;
			.footer2018__legal {
				padding-left: 0;
			}
			.footer2018__please-help {
				padding-right: 0;
				text-align: center;
				margin-top: 10px;
			}
		}
	}
}
