.component-dropdown{
    z-index: 100;
    display: block;
    position: fixed;
    min-width: 160px;
    background: white;
    color: #333;  
    visibility: hidden;
    transform: perspective(160px) rotateX(-90deg);
    transform-origin: top;
    opacity: 0;
    transition: transform 0.25s cubic-bezier(0,0,0,1), opacity 0.25s cubic-bezier(0,0,0,1);
    box-shadow: 0 0 5px rgba(0,0,0,.3);
    &--opened{
        visibility: visible;
        transform: perspective(160px) rotateX(0deg);
        opacity: 1;
    }
    &--closing{
        visibility: visible;
        transform: perspective(160px) rotateX(-90deg);
        opacity: 0;
    }
    &--user-info {
        .logged-user {
            padding: 15px 20px;
            position: relative;
            color: #fff;
            &::after {
                content:'';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: $gradientHeaderReverse;
                z-index: -1;
            }
        }
    }
    &--cart {
        padding: 20px;

        table {
            margin: 0px;
            max-width: 350px;

            th {
                border-top: 0px;
                font-size: 12px;
            }
        }
        
        .shop-button {
            margin-top: 10px;
            width: 100%;
        }
    }
    &--with-border {
        .dropdown-item {
            position: relative;
            display: block;
            width: 100%;
            padding: 10px;
            padding-left: 14px;
            &::after {
                content: "";
                display: inline-block;
                position: absolute;
                left: 0;
                top: 50%;
                bottom: 50%;
                width: 4px;
                background: rgba(255, 255, 255, 0);
                transition: background-color 0.4s, top 0.2s, bottom 0.2s;
            }
            &--active {
                font-weight: bold;
                &::after {
                    top: 0;
                    bottom: 0;
                    background: $colorMainDarker;
                }
            }

            &:hover {
                &::after {
                    top: 0;
                    bottom: 0;
                    background: lighten($colorMainDarker, 20%);
                }
            }
        }
    }
    &--products {
        display: flex;
        padding: 20px;
        max-width: 320px;
        flex-wrap: wrap;
        .product {
            cursor: pointer;
            width: 33.33%;
            text-align: center;
            padding: 15px 10px;
            &__icon {
                height: 50px;
                width: 50px;
                margin: 0 auto;
                background-size: contain;
                filter: brightness(1);
                transform: scale(1);
                transition: filter 0.2s ease, transform 0.2s ease;
            }
            &__name {
                margin-top: 5px;
                transform: translateY(0px);
                transition: transform 0.3s ease;
                position: relative;
                &--coming-soon {
                    &:after {
                        content: "Coming soon";
                        position: absolute;
                        bottom: -11px;
                        left: 0;
                        right: 0;
                        font-size: 10px;
                        opacity: 0.6;
                    }
                }
            }
            &--active {
                // font-weight: 700;
                // color: $turquoiseColor;
            }
            &:hover {
                .product__icon {
                    filter: brightness(1.2);
                    transform: scale(1.2);
                }
                .product__name{
                    transform: translateY(4px);
                }
            }
        }
    }
    .dropdown-links {
        padding: 10px 0;
        &__link {
            display: block;
            padding: 10px 20px;
            position: relative;
            &::after {
                content: "";
                display: inline-block;
                position: absolute;
                bottom: 6px;
                left: 20px;
                right: 100%;
                height: 1px;
                background: $colorMain;
                visibility: hidden;
                opacity: 0;
                transition: all .5s ease,opacity .3s;
            }
            &:hover {
                color: inherit;
                &::after {
                    left: 20px;
                    right: 20px;
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
}
