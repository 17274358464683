.page-content--contestbrowser {
    &__mobile-menu {
        position: absolute;
        right: 0;
        display: none;
        padding: 10px;
        div {
        }
        svg {
            width: 22px;
            margin-right: 5px;
            
        }
        @media all and (max-width: 1024px) {
            display: flex;
        }
    }
    h2 {
        margin: 30px 0 10px;
        font-weight: 600;
        // &:first-child {
        //     margin-top: 20px;
        // }
        @media all and (max-width: 1024px) {
            margin-top: 20px;
            font-size: 18px;
        }
    }
    &__message {
        width: 100%;
        color: #555;
        height: 160px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media all and (max-width: 1024px) {
            height: 150px;
        }
    }
    .search-content {
        position: relative;
        // max-width: 900px;
        min-height: 40vh;
        margin: 0 auto;
        &__more-results {
            margin: 20px;
            display: flex;
            justify-content: center;
        }
    }
    .search-type-selector {
        display: flex;
        margin-bottom: 20px;
        &__button {
            flex: 1;
        }
    }
    .search-box {
        position: relative;
        @media all and (max-width: 900px) {
            &--searching {
                display: fixed;
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: white;
                z-index: 9999;                
                .search-box {
                    &__content {
                        height: 54px;
                        border-bottom: 1px solid $greyLineColor;
                        box-shadow: 0px 0px 4px rgba(0,0,0,0.3);
                    }
                    &__icon--back {
                        display: flex;
                    }
                    &__results_dropdown {
                        margin: 0;
                        top: 54px;
                        right: 0;
                        bottom: 0;
                        background: transparent;
                        box-shadow: none;
                        border: 0;
                        overflow-y: auto;
                    }
                    &__result {
                        padding: 12px 12px 12px 36px;
                        border: 0;
                    }
                }
            }
        }
        &__content {
            display: flex;
            border: 1px solid #d9d9d9;
            height: 50px;
            margin-bottom: 10px;
            align-items: center;
            position: relative;
            @media all and (max-width: 1024px) {
                height: 40px;
            }
        }
        &__input {
            flex: 1;
            border: 0;
            padding: 0 10px;
            height: 40px;
            @media all and (max-width: 1024px) {
                height: 38px;
            }
            
        }
        &__icon {
            height: 100%;
            width: 35px;
            transform-origin: 50% 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #000;
            cursor: pointer;
            &--rolling {
                transition: transform 5s linear;
                transform: rotateZ(1000deg);
            }
            &--back {
                display: none;
            }
            svg {
                width: 16px;
                height: 16px;
            }
            .fa-spinner {
                width: 24px;
                height: 24px;
            }
        }
        &__results_dropdown {
            position: absolute;
            top: 0;
            left: 0;
            margin-top: 49px;
            z-index: 300;
            background: white;
            border: 1px solid #d9d9d9;
            // border-top: 0;
            box-shadow: 0 5px 4px rgba(0, 0, 0, .10);
            // margin-left: -1px;
            // margin-right: -1px;
            @media all and (max-width: 1024px) {
                margin-top: 39px;
            }
        }
        &__results {}
        &__result {
            padding: 10px;
            border-bottom: 1px solid #d9d9d9;
            &:hover {
                background: #eee;
                cursor: pointer;
            }
            &--selected {
                background: #eee;
                
                @media all and (max-width: 1024px) {
                    background: unset;
                }
            }
            &:last-child {
                border-bottom: 0;
            }
        }
    }
    .search-tags-and-buttons {
        display: flex;
        margin-bottom: 15px;
        @media all and (max-width: 1024px) {
            flex-direction: column;
        }
        &__tags {
            flex: 1;
        }
        &__buttons {
            display: flex;
            align-items: flex-start;
            .btn {
                margin-left: 10px;
                white-space: nowrap;
                @media all and (max-width: 1024px) {
                    flex: 1;
                    margin-top: 5px;
                    &:first-child {
                        margin-left: 0;
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
    .search-results {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
        grid-gap: 15px;
        margin-top: 10px;
        padding-top: 15px;
        border-top: 1px solid #c9c9c9;
        .contest-component {
            cursor: pointer;
        }
    }
    .playlist-container {
        display: flex;
        @media (max-width: 640px) {
            flex-direction: column-reverse;
        }
        &__contests{
            width: 333px;
            background: #e8e8e8;
            margin-right: 20px;
            @media (max-width: 900px) {
                width: unset;
                flex: 2;
            }
            @media (max-width: 640px) {
                margin-right: 0;
            }
        }
        &__contest {
            margin: 20px;
            border-bottom: 1px solid #b7b7b7;
            &:last-child {
                border-bottom: none;
            }
            @media (max-width: 1024px) {
                margin: 15px;
            }
        }
        &__competition {
            display: flex;
            &__name {
                flex: 1;
                font-size: 12px;
            }
            &__weight {
                font-size: 12px;
            }
        }
        &__athletes {
            padding-top: 8px;
            padding-bottom: 10px;
            display: flex;
            &__athlete {
                flex: 1;
                margin-right: 5px;
                &:last-child {
                    margin-right: 0;
                    margin-left: 5px;
                }
                &--winner {
                    font-weight: bold;
                }
                &__country {
                    display: flex;
                    align-items: center;
                    margin-top: 6px;
                    font-size: 12px;
                    .flag{
                        height: 12px;
                        margin: 0 3px;
                        border: 1px solid $greyLineColor;
                    }
                    .component-event__actor__marker {
                        margin-right: 2px;
                        &--blue {
                            margin-right: 0;
                            margin-left: 2px;
                        }
                    }
                }
                &:last-child {
                    text-align: right;
                    .playlist-container__athletes__athlete__country {
                        flex-direction: row-reverse;
                    }
                }
            }
            &__events {
                .component-event {
                    margin-bottom: 5px;
                    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
                    &:hover {
                        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.5);
                    }
                    &:last-child {
                        margin-bottom: 20px;
                    }
                }
            }
        }        
        &__video{
            flex: 3;
            width: 100%;
            .iframe-16_9 {
                position: relative;
                /*TEMPORARY*/
                @media (max-width: 640px) {
                    z-index: unset;
                }
            }
            > div {
                position: sticky;
                top: 20px;
                @media (max-width: 640px) {
                    margin-bottom: 20px;
                    position: relative;
                    top: unset;
                }
            }
            &__controls {
                margin-top: 10px;
                display: flex;
            }
        }
    }
}