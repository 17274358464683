.page--upload{
    .dnd-box{
        cursor: pointer;
        padding: 20px;
        // background: linear-gradient(0deg,#544583,transparent 70%),linear-gradient(90deg,#565c95,#6f73a3,#6e5d8d);
        background: $gradientBlue;
        background-size: cover;
        text-align: center;
        color: white;
        margin: 0 auto 20px auto;
        font-weight: 600;

        input{
            border: none;
            padding: 0;
            @media (max-width: 800px) {
                max-width: 70vw;
            }
        }

        &__icon {
            height: 50px;
            &:hover {
                color: $color2;
            }
        }
        &__container {
            border: 2px dashed white;
            padding: 40px;
            text-align: center;
            @media (max-width: 800px) {
                padding: 10px;
            }
        }
        &:hover {
            color: $color2;
            .dnd-box__container {
                border-color: $color2;
            }
        }
    }
    .video-selected-message{
        margin-bottom: 15px;
        background: #c8ffa1;
        padding: 10px;
        text-align: center;
        transform: rotateX(90deg);
        transition: transform .8s ease;
        &--rotate{
            transform: rotateX(0deg);
        }
    }
}