.page--browse {
    .browse-pagination{
        margin-top: 40px;
        text-align: center;
        &__info {
            // display: block;
            // padding-right: 10px;
            margin-top: 10px;
        }
        .btn {
            padding: 8px 10px;
            svg {
                height: 18px;
                width: 18px;
            }
        }
    }
}