.page--adminVideos {
	.full-width {
		width: 100%;
	}

	.overflow-x {
		overflow-x: auto;
	}

    .status-btn {
		width: 250px !important;
		margin-right: 20px;
    } 

    .video-iframe {
		width: 100%;
		height: 540px;
		border: 0px;
		overflow: hidden;
    }
}