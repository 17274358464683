.page-header {
    position: relative;
    color: #fff;
    &__background{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: -50px;
        background-image: $gradientPageHeader;
        z-index: -1;
        @media (max-width: $maxWidth) {
            bottom: 0;
            height: unset;
        }
        &::after {
            content:'';
            position: absolute;
            width: 100%;
            height: 100%;
            background: url('https://res.cloudinary.com/duu3v9gfg/image/fetch/t_grayscale/https://78884ca60822a34fb0e6-082b8fd5551e97bc65e327988b444396.ssl.cf3.rackcdn.com/competition_banners/wc_open2017.jpg');
            background-repeat: no-repeat;
            background-size: cover;
            opacity: 0.25;
            background-position: top center;
        }
    }
    &__content {
        text-align: left;
        max-width: $maxWidth;
        margin: 0 auto;
        padding-top: $headerHeight;
        @media all and (max-width: 1024px){
            padding: $headerHeightMobile 20px 0;
        }
        &--center {
            text-align: center;
        }
        &--padding-30 {
            padding: calc(30px + #{$headerHeight}) 0px 30px;
        }
        &--padding-40 {
            padding: calc(40px + #{$headerHeight}) 0px 40px;
        }
        &--padding-50 {
            padding: calc(50px + #{$headerHeight}) 0px 50px;
        }
    }
    &__page-title {
        margin: 0;
        padding: 50px 0;            
        font-size: 40px;
        font-weight: 700;
        text-transform: uppercase;
        @media (max-width: $maxWidth) {
            font-size: 32px;
            padding: 30px 0;    
        }
        @media (max-width: 640px) {
            font-size: 22px;
        }
    }
    &__article-title {
        padding-top: 40px;            
        font-size: 36px;
        font-weight: 600;
        margin: 0;
        @media (max-width: $maxWidth) {
            font-size: 30px;
            padding-top: 30px;    
        }
        @media (max-width: 640px) {
            font-size: 20px;
            padding-top: 20px;   
        }
        &--bold {
            font-weight: 700;
        }
    }
    &__upper-title {
        margin-top: 7px;
        margin-bottom: 7px;
        font-size: 27px;
        font-weight: lighter;
        letter-spacing: 4px;                                        
        @media (max-width: $maxWidth) {
            font-size: 22px;
        }
        @media (max-width: 640px) {
            font-size: 16px;
        }
    }
    &__subtitle {
        font-size: 16px;
        font-weight: 300;
        margin-top: 10px;
        @media all and (max-width: $maxWidth){
            font-size: 14px;
        }
    }
    .article-share {
        padding-bottom: 40px;
        @media (max-width: $maxWidth) {
            padding-bottom: 30px;   
        }
        @media (max-width: 640px) {
            padding-bottom: 20px;
        }
    }
    &--no-padding {
        padding: 0px;
    }
    &__video-meta {
        padding: 40px 0;
        &__top-text {
            font-weight: 300;
            font-size: 20px;
            margin-bottom: 5px;
            @media screen and (max-width: 1000px){
                font-size: 14px;
            }
        }
        &__title {
            align-self: center;
            font-size: 40px;
            font-weight: 700;
            text-transform: uppercase;
            @media screen and (max-width: 1000px){
                font-size: 30px;
            }
            @media screen and (max-width: 640px){
                font-size: 22px;
                margin-bottom: 5px;
            }
            
            h1, h2{
                margin: 0;
                padding: 0;
                font-size: inherit;
                font-weight: inherit;
            }
        }
        &__bottom-text {
            display: flex;
            margin: 12px 0 10px;
            flex-wrap: wrap;
            font-size: 16px;
            font-weight: 300;
            @media screen and (max-width: 1000px){
                font-size: 14px;
            }
            @media screen and (max-width: 640px){
                flex-basis: 100%;
                margin: 5px 0;
            }
        }
    }
}