.page--watch {
    .component__page-header__container {
        @media (min-width: 1001px) {
            padding: 30px 0;
        }
    }
    .video-player {
        padding-bottom: 56%;
        background-size: 100%;
        background-position: 50%;
    }
    .details {
        display: flex;
        margin: 20px 0 40px;
        @media (max-width: 640px){
            flex-direction: column-reverse;
            margin-bottom: 20px;
        }
        .description {
            margin-right: 40px;
            flex: 1;
            &__label {
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 10px;
            }
            &__text {
                line-height: 1.15em;
            }
        }
        .vote {
            .continue-with-fb-box {
                border: 1px solid #c9c9c9;
                padding: 20px;
                text-align: center;

                .continue-with-fb-text {
                    margin-bottom: 10px;
                    font-weight: 600;
                }
                @media (max-width: 640px) {
                    margin-bottom: 20px;
                } 
                
            }

            .btn {
                @media (max-width: 640px) {
                    width: 100%;
                } 
            }

            form {
                text-align: right;
            }
        }
    }
    .views-and-votes {
        display: flex;
        justify-content: space-between;
        &--2 {
            .views-and-votes__number {
                width: 49%;
            }
        }
        &--3 {
            .views-and-votes__number {
                width: 32%;
            }
        }
        &__number {
            padding: 10px;
            text-align: center;
            background: #eee;
            .number {
                font-size: 30px;
                font-weight: 700;
                @media (max-width: 640px) {
                    font-size: 20px;
                }
            }
        }
    } 
    .fb-like-container {
        margin-top: 30px;
    }
    .article-share {
        // margin: 40px 0;
        margin: 20px 0 40px 0;
        @media (max-width: 640px) {
            margin-top: 20px;
        }
    }
    .related-videos {
        &__label {
            font-size: 18px;
            font-weight: 700;

        }
        select{
            font-size: 14px;
        }
        .video-grid {
            margin-top: 15px;
        }
    }

    .vote-msg {
        padding: 15px 10px;
        text-align: center;
        margin-bottom: 20px;
        background-color: #eee;
        margin-top: 0px;
        font-size: 18px;
        opacity: 0;
        transition: all ease .5s;
        position: sticky;
        top: 95px;
        z-index: 1000;
        cursor: pointer;
        @media (max-width: 1024px) {
            top: 50px;
        }
        &--show{
            opacity: 1;
            animation-name: votemsganim;
            animation-duration: 4s;
            animation-delay: 1s;
        }
        &__close{
            font-size: 14px;
            margin-top: 5px;
            //transition: color .3s ease;
            //&:hover{
            //    color: $blueColor;
            //}
        }
    }    
}

.jw-pause-overlay{
    position: absolute;
    font-size: 14px;
    //left: 0;
    //right: 0;
    //top: 0;
    //bottom: 0;
    right: -500px;
    bottom: 100px;
    overflow: hidden;
    display: none;
    opacity: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: opacity .5s ease, right .5s ease;
    padding: 10px;
    color: #ffffff;
    @media (max-width: 640px) {
        font-size: 12px;
        bottom: unset;
        top: 0;
        padding: 5px;
        max-width: 140px;
    }
    &--enable{
        display: block;
        right: 0px;
    }
    &--in{
        opacity: 1;
    }
    .athlete_card {
        @media (max-width: 640px) {
            margin-top: 2px;
            font-size: 12px;
        }
        &:hover {
            color: #ffffff;
        }
        &__img-name-flag {
            display: flex;
            margin-bottom: 5px;
            @media (max-width: 640px) {
                margin-bottom: 2px;
            }
        }
        &__title {
            font-weight: 700;
            margin-bottom: 10px;
            @media (max-width: 640px) {
                margin-bottom: 2px;
            }
        }
        &__img {
            img {
                width: 34px;
                @media (max-width: 640px) {
                    width: 25px;
                }
            }   
        }
        &__name-flag {            
            flex: 1;
            padding-left: 10px;

        }
        &__name {
            margin-bottom: 5px;
            @media (max-width: 640px) {
                margin-bottom: 0;
            }
        }
        &__flag {
            font-size: 12px;
            @media (max-width: 640px) {
                font-size: 10px;
            }
            img {
                width: 16px;
                margin-bottom: -2px;
                margin-right: 5px;
                @media (max-width: 640px) {
                    width: 13px;
                    margin-bottom: 0;
                }
            }
        }
        &__stats {
            @media (max-width: 640px) {
                font-size: 10px;
            }
            
        }
        &__call-to-action {
            margin-top: 5px;
            font-weight: 700;
            white-space: nowrap;
            color: #f9ce89;
            @media (max-width: 640px) {
                margin-top: 2px;
            }
        }
    }
}

@keyframes votemsganim {
    0%{
        background-color: #eee;
    }
    50%{
        background-color: $goldColor;
    }
    100%{
        background-color: #eee;
    }
}

.page--my_videos_processing{
    .video-processing-container{
        @media (min-width: 801px) {
            text-align: center;
            img {
                width: 60%;
            }
        }
    }
}
