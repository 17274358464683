$size-dot: 18px;
$width-item: 160px;
$mobile-item-margin: 20px;
.timeline {
    margin: 0 auto;
    margin-top: 50px;
    @media (max-width: 900px ) {
        text-align: left;
        display: flex;
        justify-content: center;
    }
    &__container {
        @media (max-width: 900px ) {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
        }
    }
    &__item {
        position: relative;
        width: $width-item;
        display: inline-block;
        @media (max-width: 900px ) {
            display: flex;
            width: unset;
            margin-bottom: 20px;
        }
        &::after {
            position: absolute;
            content: '';
            height: 1px;
            width: calc( 100% - (#{$size-dot} / 2) - 4px);
            background: white; 
            margin: auto;
            left: calc( #{$width-item} / 1.82 );
            top: 8px;
            @media (max-width: 900px ) {
                height: calc( 100% - #{$size-dot} + #{$mobile-item-margin} + 1px);
                width: 1px;
                left: 9px;
                margin: 0;
                top: 17px;
            }
        }
        &:last-child::after {
            display: none;
        }
        &__texts {
            
        }
        &__dot {
            position: relative;
            margin: auto;
			height: $size-dot;
            width: $size-dot;
            min-width: $size-dot;            
			border-radius: 50%;
            border: 1px solid #fff;
            @media (max-width: 900px ) {
                margin: 0 10px 0 0;
            }
        }
        &__title {
            font-weight: 700;
            font-size: 16px;
            margin-top: 10px;
            @media (max-width: 900px ) {
                margin-top: 0px;
            }
        }
        &__date {
            font-weight: 300;
            margin-top: 6px;
        }
        &--active, &--past {
            .timeline__item__dot {
                border-color: $color2;
                &::after {
					position: absolute;
					content: '';
					height: calc( #{$size-dot} / 2.25);
					width: calc( #{$size-dot} / 2.25);
					border-radius: 50%;
					background: $color2; 
					margin: auto;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
                }
            }
        }
        &--active {
            color: $color2;
        }
        &--past {
            &::after {
                background: $color2; 
            }
        }
    }
}