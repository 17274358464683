.component--filters {
    .filters-row {
        margin-bottom: 20px;
        display: flex;
        font-size: px-size(10);

        @media screen and (max-width: 640px) {
            flex-direction: column;
        }

        .one-filter {
            width: 100%;

            .filter-label {
                font-weight: 700;
                margin-bottom: 0.5em;
                display: block;
                @media screen and (max-width: 640px) {
                    display: none;
                }

                .reset {
                    display: inline-block;
                    margin-left: 1em;
                    font-size: 90%;
                    color: $linkOnWhite;
                    font-weight: 400;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            &:not([style*="display: none"]):not(:last-child) {
                padding-right: 20px;
                @media screen and (max-width: 640px) {
                    padding: 0;
                    padding-bottom: 20px;
                }
            }
        }

        .ButtonsSelect .opt {
            text-decoration: none;
            color: #000;
            &--nowrap{
                white-space: nowrap;
            }
            @media screen and (min-width: 640px) {
                flex-direction: column;
                flex: 1;
                &__large {
                    width: 10em;
                }
                &__sm {
                    width: 4em;
                }
                &__xs {
                    width: 2em;
                }
                &__noborder {
                    border: 0;
                }             
            }
            @media screen and (max-width: 640px) {
                flex-grow: 1;
            }
        }

        select:not(.opt), input {
            border: 1px solid #d9d9d9;
            padding: 8px;
            width: 100%;
            font-size: px-size(10);
            font-weight: 700;
            line-height: 1em;
            box-sizing: border-box;
            height: 38px;

            &.no-value {
                font-weight: 400;
            }
        }
        &--margin-0 {
            margin: 0;
        }
    }

    .ButtonsSelect {
        display: flex;
        height: 38px;

        .opt {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #d9d9d9;
            border-right: none;
            padding: 10px; 
            text-align: center;

            &:last-child {
                border-right: 1px solid #d9d9d9;
            }
            
            @include bottom-line-decoration($colorMain);

            &.active {
                font-weight: 700;
            }
            &--disabled{
                cursor: default;
                @include bottom-line-decoration(transparent);
            }
        }
    }
}